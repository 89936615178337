import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import {
    salesLayoutConfigVar,
    currentOrderVar,
    ISalesLayoutConfig,
} from '../../layouts/sales-layout/sales-layout-state';
import { SalesLayout } from '../../layouts/sales-layout';
import { IPageBreadcrumb } from '../../layouts/components/page-breadcrumbs';
import { OrderReturnsPageContent } from './order-returns-page-content';

const OrderReturnsPage: React.FC = () => {
    const [pageBreadcrumbs, setPageBreadcrumbs] = useState<IPageBreadcrumb[]|undefined>(undefined);
    const t = usePhraseTranslater();

    const config: ISalesLayoutConfig = useReactiveVar(salesLayoutConfigVar);
    const currentOrder = useReactiveVar(currentOrderVar);

    const { salesConfig } = config;
    const { customerOrdersUrl } = salesConfig;
    const pageTitle = t('Your Returns');

    const orderReturnsPageComponent: JSX.Element = (
        <OrderReturnsPageContent />
    );

    useEffect(() => {
        if (!currentOrder) {
            return;
        }

        const { number, order_urls: orderUrls } = currentOrder;
        const { details_url: orderDetailsUrl } = orderUrls;

        const orderReturnsPageBreadcrumbs: IPageBreadcrumb[] = [
            {
                label: t('Your Orders'),
                url: customerOrdersUrl,
            },
            {
                label: t('Your Order #%1', number),
                url: orderDetailsUrl,
            },
            {
                label: pageTitle,
            },
        ];
        setPageBreadcrumbs(orderReturnsPageBreadcrumbs);
    }, [currentOrder]);

    return (
        <SalesLayout
            pageKey="order-returns"
            pageTitle={pageTitle}
            pageContent={orderReturnsPageComponent}
            pageBreadcrumbs={pageBreadcrumbs}
            showLeftSidebar={false}
            isGuestPage={false}
            loadCountries
            loadWidgets
            splitOrderItems={false}
        />
    );
};

export { OrderReturnsPage as default };
